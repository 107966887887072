import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { SocketProvider } from './context';
import '../node_modules/@andreyshpigunov/x/dist/x.css';
import './index.css';
import App from './App';
import Unauthorized from './components/Unauthorized';


const root = document.getElementById('root');
ReactDOM
  .createRoot(root)
  .render(
  //<React.StrictMode>
    <SocketProvider>
      <App/>
    </SocketProvider>
  //</React.StrictMode>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
