//
//	Logo.jsx
//	playrps.ru
//
//	Created by Andrey Shpigunov on 05.11.2024.
//


import logo from '../assets/logo.png';


export default function Logo() {
  return (
    <div className="max200 mxa py6">
      <img className="max200" src={logo} width="220" height="176"/>
    </div>
  )
}
