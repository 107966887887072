//
//  Panel.jsx
//  playrps.ru |
//  Created by Andrey Shpigunov at 27.01.2025
//  All right reserved.
//


import rock from '../assets/rock.png';
import paper from '../assets/paper.png';
import scissors from '../assets/scissors.png';


export default function Panel() {
  return (
    <div className="flex jcsa max300 mxa my4">
      <a className="button button_icon">
        <img src={rock} width="100" height="100"/>
      </a>
      <a className="button button_icon">
        <img src={paper} width="100" height="100"/>
      </a>
      <a className="button button_icon">
        <img src={scissors} width="100" height="100"/>
      </a>
    </div>
  )
}
