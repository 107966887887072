//
//	NewGame.jsx
//	playrps.ru
//
//	Created by Andrey Shpigunov on 05.11.2024.
//


import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../components/Logo';
import Header from '../components/Header';
import { Buttons, Button } from '../components/Buttons';
import { Block } from '../components/Block';


export const NewGame = () => {
  
  const location = useLocation();
  const navigate = useNavigate();
  const webApp = window.Telegram.WebApp;
  
  useEffect(() => {
    webApp.BackButton.show();
    webApp.onEvent('backButtonClicked', () => {
      navigate('/');
    });
    // socket.emit('play')
  }, [location])
  
  
  // socket.on('ok-play', (data) => {
    // console.log('Ok, play!', data)
  // })
  
  
  return (
    <>
      <Logo/>
      <Header text="New Game"/>
      <Block>
        <div className="flex aic jcsa mb3">
          <div className="fs18 fw700" style={{borderBottom: 'solid 3px'}}>Play global</div>
          <div className="fs18 pointer op2" onClick={() => {
            webApp.showAlert('Invites will be available later')
          }}>Invite user</div>
        </div>
        <div className="py8 ac">Coming soon</div>
        <Buttons>
          {/* <Button name="Go Back" to="/"/> */}
          <Button name="Create game" onClick={() => navigate('/')}/>
        </Buttons>
      </Block>
      <Buttons>
        {/* <Button name="Go Back" to="/"/> */}
        <Button name="Go Back" onClick={() => navigate('/')}/>
      </Buttons>
    </>
  );
}
