import React from 'react';
import ReactDOM from 'react-dom/client';
import '../node_modules/@andreyshpigunov/x/dist/x.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import io from 'socket.io-client';


let isLocalhost = Boolean(window.location.hostname === 'localhost');

if (isLocalhost) {
  console.log('Started on localhost');
  window.socket = io('http://localhost:3000');
} else {
  console.log('Started on production');
  window.socket = io('https://api.playrps.ru');
}

window.socket.on('connect', function () {
  
    if (isLocalhost) {
      console.log('Connected to localhost:3000');
    } else {
      console.log('Connected to https://api.playrps.ru');
      
      console.log(window.Telegram.WebApp.initData.user);
      
      let initData = window.Telegram.WebApp.initData;
      window.socket.emit('server.initData', initData);
      console.log('server.initData', initData);
      window.socket.on('app.initData', (data) => {
        console.log('app.initData', data)
      })
    }
    
    console.log(window.Telegram.WebApp);
    // window.Telegram.WebApp.showAlert('Connected');
    window.socket.on('clientEvent', function (data) {
        console.log('message from the server:', data);
        window.socket.emit('serverEvent', "thanks server! for sending '" + data + "'");
    });
    
    window.socket.emit('WebApp.initData', window.Telegram.WebApp.initData);
    window.socket.on('WebApp.initData.approved', () => {
      console.log('Thanks for this data: ' + window.Telegram.WebApp.initData);
    });
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
//  <React.StrictMode>
    <App/>
//  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
