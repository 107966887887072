//
//  context.js
//  playrps.ru | Context
//  Created by Andrey Shpigunov at 26.01.2025
//  All right reserved.
//


import React, { createContext } from 'react';
import { io } from 'socket.io-client';

const isLocalhost = Boolean(window.location.hostname === 'localhost');
const socket = (isLocalhost ? io('http://localhost:3000') : io('https://api.playrps.ru'));
const SocketContext = createContext(socket);

socket.on('connect', () => console.log('Connected to socket'));

const SocketProvider = (props) => {
  return (
    <SocketContext.Provider value={socket}>
      {props.children}
    </SocketContext.Provider>
  )
}
export { SocketContext, SocketProvider };
