//
//  Header.jsx
//  playrps.ru | Headers
//  Created by Andrey Shpigunov at 27.01.2025
//  All right reserved.
//


import { useNavigate } from "react-router-dom"
import logo from '../assets/logo.png';
import './Header.css';


export default function Header(props) {
  return (
    <div className="mx4 ac">
      <h1 className="my0">{props.text}</h1>
    </div>
  )
}

export function HeaderLine(props) {
  
  let navigate = useNavigate();
  
  return (
    <div className="headerLine | flex aic jcsb p2">
      <div className="headerLine-logo">
        <img src={logo} width="220" height="176"/>
      </div>
      <div className="headerLine-title | fs26 fw700">
        {props.title}
      </div>
      <div className="headerLine-closer | ar">
        <a className="fs36 lh0" onClick={() => navigate(-1)}>×</a>
      </div>
    </div>
  )
}
