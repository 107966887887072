//
//  Info.jsx
//  playrps.ru | Game information
//  Created by Andrey Shpigunov at 20.01.2025
//  All right reserved.
//


import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { Buttons, Button } from '../components/Buttons';
import { Block } from '../components/Block';


export const Info = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const webApp = window.Telegram.WebApp;
  
  useEffect(() => {
    webApp.BackButton.show();
    webApp.onEvent('backButtonClicked', () => {
      navigate('/');
    });
  }, [location])
  
  return (
    <>
      <Header text="Info"/>
      <Block>
        <p>Telegram приложение «Камень, ножницы, бумага» запускается из бота @PlayRockPaperScissorsBot.</p>
        <p>Каждый пользователь, запустивший бота, автоматически анонимно регистрируется в системе и может играть.</p>
        <p>Функционально приложение состоит из 5 частей.</p>
      </Block>
      <Block>
        <h2>1. Telegram bot</h2>
        <p>@PlayRockPaperScissorsBot</p>
        <p>Бот регистрирует пользователя и сможет в будущем выполянть некоторые задачи через тестовые команды. В данное время он служит только для регистрации пользователей и запуска игры.</p>
      </Block>
      <Block>
        <h2>2. Frontend</h2>
        <p>Интерфейс игры, написан на React.</p>
        <p>Располагается на удаленном сервере по адресу <a href="https://playrps.ru">https://playrps.ru</a>.</p>
        <p>Сейчас открывается с любого устройства, но будет доступна только из Telegram.</p>
      </Block>
      <Block>
        <h2>3. Backend API</h2>
        <p>Серверная часть игры, написана на NodeJS.</p>
        <p>Отвечает за работоспособность интерфейса, приложение постоянно взаимодействует с API.</p>
        <p>Располагается на удаленном сервере по адресу https://api.playrps.ru.</p>
      </Block>
      <Block>
        <h2>4. Admin</h2>
        <p>Административная часть. Пока готова лишь основа.</p>
        <p>Служит для управления игрой и пользователями. Например в административной части можно будет создавать турниры, банить пользователей и управлять другими настройками.</p>
      </Block>
      <Block>
        <h2>5. Database</h2>
        <p>База данных MySQL.</p>
        <p>Место, где хранятся все пользователи, состояние игры, турниры, статистика и другое.</p>
      </Block>
      <Buttons>
        {/* <Button name="Go Back" to="/"/> */}
        <Button name="Go Back" onClick={() => navigate('/')}/>
      </Buttons>
      <br/>
      <br/>
    </>
  );
}
