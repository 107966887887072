//
//	App.js
//	playrps.ru
//
//	Created by Andrey Shpigunov on 05.11.2024.
//


import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { SocketContext } from './context';
import { Main } from './pages/Main';
import { Play } from './pages/Play';
import { NewGame } from './pages/NewGame';
import { FindGame } from './pages/FindGame';
import { Tournaments } from './pages/Tournaments';
import { Rating } from './pages/Rating';
import { Settings } from './pages/Settings';
import { Info } from './pages/Info';
import Unauthorized from './components/Unauthorized';
import './App.css';


export default function App(props) {
  
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const initData = window.Telegram.WebApp.initData;
  const socket = useContext(SocketContext);
  
  useEffect(() => {
    let isLocalhost = Boolean(window.location.hostname === 'localhost');
    console.log('Started on ' + (isLocalhost ? 'localhost' : 'production'));
    
    if (initData) {
      socket.on('connect', function () {
        console.log('Connected to ' + (isLocalhost ? 'localhost' : 'production'));
        
        if (!isLocalhost) {
          socket.emit('server.auth', initData);
          socket.on('app.auth', (data) => {
            setIsAuthenticated(data.auth)
          })
        }
        
        // console.log(window.Telegram.WebApp);
        // window.Telegram.WebApp.showAlert('Connected');
        // socket.on('clientEvent', function (data) {
            // console.log('message from the server:', data);
            // socket.emit('serverEvent', "thanks server! for sending '" + data + "'");
        // });
        
        
        // socket.on('unauthorized', () => {
          //
        // })
      })
    } else {
      setIsAuthenticated(false || isLocalhost)
    }
    
  })
  
  
  
  
  
  return (
    <>
      {!isAuthenticated ? (
        <Unauthorized/>
      ) : (
        <div className="app mxa max500">
          <BrowserRouter>
            <Routes>
              <Route exact path='/' element={<Main/>}/>
              <Route path='play' element={<Play/>}/>
              <Route path='newgame' element={<NewGame/>}/>
              <Route path='findgame' element={<FindGame/>}/>
              <Route path='tournaments' element={<Tournaments/>}/>
              <Route path='rating' element={<Rating/>}/>
              <Route path='settings' element={<Settings/>}/>
              <Route path='info' element={<Info/>}/>
              {/* default redirect to home page */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            {/* <Routes>
              <Route path="/" element={<Main/>}>
                <Route index element={<div>Main page</div> }/>
                <Route path="newgame" element={<NewGame/>}/>
                <Route path="two" element={<PageTwo/>}/>
                <Route path="profile" element={<Profile/>}/>
              </Route>
            </Routes> */}
          </BrowserRouter>
        </div>
        // <div id="modalConnecting" x-modal="true" className="modal-content">
        //   Connecting...
        // </div>
        // <script>x.init();x.modal.show('modalConnecting');</script>
      )}
    </>
  )
}
