//
//	App.js
//	playrps.ru
//
//	Created by Andrey Shpigunov on 05.11.2024.
//


import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Main } from './pages/Main';
import { NewGame } from './pages/NewGame';
import { FindGame } from './pages/FindGame';
import { Tournaments } from './pages/Tournaments';
import { Rating } from './pages/Rating';
import { Settings } from './pages/Settings';
import { Info } from './pages/Info';
import './App.css';


function App() {
  return (
    <>
    <div className="app mxa max500">
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Main/>}/>
          <Route path='newgame' element={<NewGame/>}/>
          <Route path='findgame' element={<FindGame/>}/>
          <Route path='tournaments' element={<Tournaments/>}/>
          <Route path='rating' element={<Rating/>}/>
          <Route path='settings' element={<Settings/>}/>
          <Route path='info' element={<Info/>}/>
          {/* default redirect to home page */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        {/* <Routes>
          <Route path="/" element={<Main/>}>
            <Route index element={<div>Main page</div> }/>
            <Route path="newgame" element={<NewGame/>}/>
            <Route path="two" element={<PageTwo/>}/>
            <Route path="profile" element={<Profile/>}/>
          </Route>
        </Routes> */}
      </BrowserRouter>
    </div>
    <div id="modalConnecting" x-modal="true" className="modal-content">
      Connecting...
    </div>
    <script>x.init();x.modal.show('modalConnecting');</script>
    </>
  )
}

export default App;
