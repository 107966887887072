//
//	Header.jsx
//	playrps.ru
//
//	Created by Andrey Shpigunov on 05.11.2024.
//


export default function Header(props) {
  return (
    <div className="mx4 ac">
      <h1 className="my0">{props.text}</h1>
    </div>
  )
}
